<template>
  <div
    v-if="$store.state.fastPermissoes.administrador == 'S' && $store.state.fastPermissoes.ativo == 'S'"
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
              >Modo admin</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Personalização da plataforma</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left mb-4">
                <h1 class="aluno_font_color">
                  Personalizar
                  <span id="txtDashboardNomePlataforma">plataforma</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12">
                  <tabs :options="{ useUrlFragment: false }">
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Informações da plataforma"
                    >
                      <div class="row justify-content-between">
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                          <label>Nome da plataforma</label>
                          <input
                            v-model="$store.state.fastPlataforma.nome_plataforma"
                            class="form-control form-control-lg"
                            type="text"
                            @keyup="$store.state.fastPlataforma.alteracao_pendente = true"
                          >
                          <div
                            v-if="$store.state.fastPlataforma.alteracao_pendente"
                            class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                          >
                            <button
                              class="btn btn-primary blink_me ml-2 mr-2"
                              @click="alteraFastPlataformaNome()"
                            >
                              Salvar alterações
                            </button>
                            <button
                              class="btn btn-secondary ml-2 mr-2"
                              @click="getPlataformaData()"
                            >
                              Cancelar
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-between mt-4">
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                          <span
                            v-tooltip.bottom-start="{ content: exibeTooltip('titulo_banner') }"
                            class="mr-1"
                          >
                            <b-icon-question-circle />
                          </span>
                          <label>Título do banner</label>
                          <input
                            v-model="$store.state.fastPersonalizacao.titulo_banner"
                            class="form-control form-control-lg"
                            type="text"
                            @keyup="$store.state.fastPersonalizacao.alteracao_pendente = true"
                          >
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                          <span
                            v-tooltip.bottom-start="{ content: exibeTooltip('titulo_banner_color') }"
                            class="mr-1"
                          >
                            <b-icon-question-circle />
                          </span>
                          <label>Cor do título do banner</label>
                          <input
                            v-model="$store.state.fastPersonalizacao.titulo_banner_color"
                            class="form-control form-control-lg"
                            type="color"
                            @click="$store.state.fastPersonalizacao.alteracao_pendente = true"
                          >
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                          <span
                            v-tooltip.bottom-start="{ content: exibeTooltip('titulo_boas_vindas') }"
                            class="mr-1"
                          >
                            <b-icon-question-circle />
                          </span>
                          <label>Título boas vindas</label>
                          <input
                            v-model="$store.state.fastPersonalizacao.titulo_boas_vindas"
                            class="form-control form-control-lg"
                            type="text"
                            @keyup="$store.state.fastPersonalizacao.alteracao_pendente = true"
                          >
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                          <span
                            v-tooltip.bottom-start="{ content: exibeTooltip('detalhes_boas_vindas') }"
                            class="mr-1"
                          >
                            <b-icon-question-circle />
                          </span>
                          <label>Texto de boas vindas</label>
                          <textarea
                            v-model="$store.state.fastPersonalizacao.detalhes_boas_vindas"
                            class="form-control form-control-lg"
                            type="text"
                            @keyup="$store.state.fastPersonalizacao.alteracao_pendente = true"
                          />
                        </div>
                        <div
                          v-if="$store.state.fastPersonalizacao.alteracao_pendente"
                          class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                        >
                          <button
                            class="btn btn-primary blink_me ml-2 mr-2"
                            @click="alteraFastPersonalizacao()"
                          >
                            Salvar alterações
                          </button>
                          <button
                            class="btn btn-secondary ml-2 mr-2"
                            @click="getPlataformaData()"
                          >
                            Cancelar
                          </button>
                        </div>
                      </div>
                    </tab>
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Atendimento"
                    >
                      <div class="row justify-content-between">
                        <div class="col-12 mb-4">
                          <h4>Informações de atendimento</h4>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                          <label>Email do pedagógico</label>
                          <input
                            v-model="$store.state.fastPersonalizacao.email_pedagogico"
                            class="form-control form-control-lg"
                            type="email"
                            @keyup="$store.state.fastPersonalizacao.alteracao_pendente = true"
                          >
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                          <label>Telefone do pedagógico</label>
                          <input
                            v-model="$store.state.fastPersonalizacao.telefone_pedagogico"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            class="form-control form-control-lg"
                            type="text"
                            @keyup="$store.state.fastPersonalizacao.alteracao_pendente = true"
                          >
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                          <label>Email da secretaria</label>
                          <input
                            v-model="$store.state.fastPersonalizacao.email_secretaria"
                            class="form-control form-control-lg"
                            type="email"
                            @keyup="$store.state.fastPersonalizacao.alteracao_pendente = true"
                          >
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                          <label>Telefone da secretaria</label>
                          <input
                            v-model="$store.state.fastPersonalizacao.telefone_secretaria"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            class="form-control form-control-lg"
                            type="text"
                            @keyup="$store.state.fastPersonalizacao.alteracao_pendente = true"
                          >
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                          <label>Email do financeiro</label>
                          <input
                            v-model="$store.state.fastPersonalizacao.email_financeiro"
                            class="form-control form-control-lg"
                            type="email"
                            @keyup="$store.state.fastPersonalizacao.alteracao_pendente = true"
                          >
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                          <label>Telefone do financeiro</label>
                          <input
                            v-model="$store.state.fastPersonalizacao.telefone_financeiro"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            class="form-control form-control-lg"
                            type="text"
                            @keyup="$store.state.fastPersonalizacao.alteracao_pendente = true"
                          >
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                          <label>Email do suporte técnico</label>
                          <input
                            v-model="$store.state.fastPersonalizacao.email_suporte"
                            class="form-control form-control-lg"
                            type="email"
                            @keyup="$store.state.fastPersonalizacao.alteracao_pendente = true"
                          >
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                          <label>Telefone do suporte técnico</label>
                          <input
                            v-model="$store.state.fastPersonalizacao.telefone_suporte"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            class="form-control form-control-lg"
                            type="text"
                            @keyup="$store.state.fastPersonalizacao.alteracao_pendente = true"
                          >
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                          <label>Email de atendimento</label>
                          <input
                            id="txtEmailAtendimento"
                            v-model="$store.state.fastPersonalizacao.email_atendimento"
                            class="form-control form-control-lg"
                            type="email"
                            @keyup="$store.state.fastPersonalizacao.alteracao_pendente = true"
                          >
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                          <label>Email de atendimento do professor</label>
                          <input
                            id="txtEmailAtendimentoProfessor"
                            v-model="$store.state.fastPersonalizacao.email_atendimento_professor"
                            class="form-control form-control-lg"
                            type="email"
                            @keyup="$store.state.fastPersonalizacao.alteracao_pendente = true"
                          >
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                          <label>Email de atendimento do RH</label>
                          <input
                            id="txtEmailAtendimentoRh"
                            v-model="$store.state.fastPersonalizacao.email_atendimento_rh"
                            class="form-control form-control-lg"
                            type="email"
                            @keyup="$store.state.fastPersonalizacao.alteracao_pendente = true"
                          >
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                          <label>Chat online</label>
                          <input
                            id="txtChatOnline"
                            v-model="$store.state.fastPersonalizacao.chat_on_line"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            class="form-control form-control-lg"
                            type="text"
                            @keyup="$store.state.fastPersonalizacao.alteracao_pendente = true"
                          >
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                          <label>Whatsapp</label>
                          <input
                            id="txtWhatsapp"
                            v-model="$store.state.fastPersonalizacao.whatsapp"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            class="form-control form-control-lg"
                            type="text"
                            @keyup="$store.state.fastPersonalizacao.alteracao_pendente = true"
                          >
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                          <label>Telefone</label>
                          <input
                            id="txtTelefone"
                            v-model="$store.state.fastPersonalizacao.telefone"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            class="form-control form-control-lg"
                            type="text"
                            @keyup="$store.state.fastPersonalizacao.alteracao_pendente = true"
                          >
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                          <label>Link para chamado</label>
                          <input
                            v-model="$store.state.fastPersonalizacao.link_chamado"
                            class="form-control form-control-lg"
                            type="text"
                            @keyup="$store.state.fastPersonalizacao.alteracao_pendente = true"
                          >
                        </div>
                        <div
                          v-if="$store.state.fastPersonalizacao.alteracao_pendente"
                          class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                        >
                          <button
                            class="btn btn-primary blink_me ml-2 mr-2"
                            @click="alteraFastPersonalizacao()"
                          >
                            Salvar alterações
                          </button>
                          <button
                            class="btn btn-secondary ml-2 mr-2"
                            @click="getFastPersonalizacao($route.params.id_plataforma, true)"
                          >
                            Cancelar
                          </button>
                        </div>
                      </div>
                    </tab>
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Imagens"
                    >
                      <div class="row justify-content-between">
                        <div class="col-12 mb-4">
                          <h4>Imagens da plataforma</h4>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <div class="row p-3">
                            <div class="col-12 card p-4">
                              <strong>
                                <span
                                  v-tooltip.bottom-start="{ content: exibeTooltip('banner_meus_cursos') }"
                                  class="mr-1"
                                >
                                  <b-icon-question-circle />
                                </span>Banner Meus Cursos
                              </strong>
  
                              <div
                                v-if="$store.state.fastPersonalizacao.banner_meus_cursos"
                                class="mt-2 mb-2"
                              >
                                <img
                                  :src="$store.state.fastPersonalizacao.banner_meus_cursos"
                                  style="max-width: 100%"
                                >
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-12 mt-4 p-0">
                                <!-- upload aqui -->
                                <input
                                  id="banner_meus_cursos"
                                  type="file"
                                  class="custom-file-input"
                                  accept="image/*"
                                  @change="setImageBannerMeusCursos"
                                >
                                <label
                                  class="custom-file-label"
                                  for="banner_meus_cursos"
                                >Selecione uma imagem...</label>
                                <label class="message-field-label mt-2">Dimensões sugeridas: 2034 x 180 pixels</label>
                                <div class="invalid-feedback">
                                  Arquivo inválido
                                </div>
                              
                                <modal
                                  name="modalImageCropBannerMeusCursos"
                                  height="auto"
                                  :shift-y="0.1"
                                  :focus-trap="true"
                                  :adaptive="true"
                                >
                                  <div class="row">
                                    <div class="col-12 pt-4 text-center">
                                      <h4>Recorte a imagem</h4>
                                    </div>
                                    <div
                                      v-if="$store.state.fastPersonalizacao.banner_meus_cursos"
                                      class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                                    >
                                      <vue-cropper
                                        ref="cropper"
                                        :src="$store.state.fastPersonalizacao.banner_meus_cursos"
                                        :auto-crop-area="1"
                                        :aspect-ratio="96 / 11"
                                      />
                                      <div class="text-center mt-2">
                                        <a
                                          class="btn btn-primary"
                                          href="#"
                                          role="button"
                                          @click.prevent="cropImage('BannerMeusCursos')"
                                        >Recortar</a>
                                      </div>
                                    </div>
                                  </div>
                                </modal>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <div class="row p-3">
                            <div class="col-12 card p-4">
                              <strong>
                                <span
                                  v-tooltip.bottom-start="{ content: exibeTooltip('imagem_informativa') }"
                                  class="mr-1"
                                >
                                  <b-icon-question-circle />
                                </span>Imagem informativa
                              </strong>
                              <div
                                v-if="$store.state.fastPersonalizacao.imagem_informativa"
                                class="mt-2 mb-2"
                              >
                                <img
                                  :src="$store.state.fastPersonalizacao.imagem_informativa"
                                  style="max-width: 100%"
                                >
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                                <!-- upload aqui -->
                                <input
                                  id="imagem_informativa"
                                  type="file"
                                  class="custom-file-input"
                                  accept="image/*"
                                  @change="setImageImagemInformativa"
                                >
                                <label
                                  class="custom-file-label"
                                  for="imagem_informativa"
                                >Selecione uma imagem...</label>
                                <div class="invalid-feedback">
                                  Arquivo inválido
                                </div>
                                <modal
                                  name="modalImageCropImagemInformativa"
                                  height="auto"
                                  :shift-y="0.1"
                                  :focus-trap="true"
                                  :adaptive="true"
                                >
                                  <div class="row">
                                    <div class="col-12 pt-4 text-center">
                                      <h4>Recorte a imagem</h4>
                                    </div>
                                    <div
                                      v-if="$store.state.fastPersonalizacao.imagem_informativa"
                                      class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                                    >
                                      <vue-cropper
                                        ref="cropper"
                                        :src="$store.state.fastPersonalizacao.imagem_informativa"
                                        :auto-crop-area="1"
                                        :aspect-ratio="10 / 7"
                                      />
                                      <div class="text-center mt-2">
                                        <a
                                          class="btn btn-primary"
                                          href="#"
                                          role="button"
                                          @click.prevent="cropImage('ImagemInformativa')"
                                        >Recortar</a>
                                      </div>
                                    </div>
                                  </div>
                                </modal>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <div class="row p-3">
                            <div class="col-12 card p-4">
                              <strong>
                                <span
                                  v-tooltip.bottom-start="{ content: exibeTooltip('logo_plataforma') }"
                                  class="mr-1"
                                >
                                  <b-icon-question-circle />
                                </span>Logo da plataforma
                              </strong>
                              <div
                                v-if="$store.state.fastPlataforma.logo_plataforma"
                                class="mt-2 mb-2"
                              >
                                <img
                                  :src="$store.state.fastPlataforma.logo_plataforma"
                                  style="max-width: 100%"
                                >
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-12 mt-4 p-0">
                                <!-- upload aqui -->
                                <input
                                  id="logo_plataforma"
                                  type="file"
                                  class="custom-file-input"
                                  accept="image/*"
                                  @change="setImageLogoPlataforma"
                                >
                                <label
                                  class="custom-file-label"
                                  for="logo_plataforma"
                                >Selecione uma imagem...</label>
                                <label class="message-field-label mt-2">Dimensões sugeridas: 192 x 142 pixels</label>

                                <div class="invalid-feedback">
                                  Arquivo inválido
                                </div>
                                <modal
                                  name="modalImageCropLogoPlataforma"
                                  height="auto"
                                  :shift-y="0.1"
                                  :focus-trap="true"
                                  :adaptive="true"
                                >
                                  <div class="row">
                                    <div class="col-12 pt-4 text-center">
                                      <h4>Recorte a imagem</h4>
                                    </div>
                                    <div
                                      v-if="$store.state.fastPlataforma.logo_plataforma"
                                      class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                                    >
                                      <vue-cropper
                                        ref="cropper"
                                        :src="$store.state.fastPlataforma.logo_plataforma"
                                        :auto-crop-area="1"
                                        :aspect-ratio="10 / 7"
                                      />
                                      <div class="text-center mt-2">
                                        <a
                                          class="btn btn-primary"
                                          href="#"
                                          role="button"
                                          @click.prevent="cropImage('LogoPlataforma')"
                                        >Recortar</a>
                                      </div>
                                    </div>
                                  </div>
                                </modal>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <div class="row p-3">
                            <div class="col-12 card p-4">
                              <strong>
                                <span
                                  v-tooltip.bottom-start="{ content: exibeTooltip('banner_plataforma') }"
                                  class="mr-1"
                                >
                                  <b-icon-question-circle />
                                </span>Banner da plataforma
                              </strong>
                              <div
                                v-if="$store.state.fastPlataforma.banner_plataforma"
                                class="mt-2 mb-2"
                              >
                                <img
                                  :src="$store.state.fastPlataforma.banner_plataforma"
                                  style="max-width: 100%"
                                >
                              </div>
                              <!-- upload aqui -->
                              <div class="col-sm-12 col-md-12 col-lg-12 mt-4 p-0">
                                <input
                                  id="banner_plataforma"
                                  type="file"
                                  class="custom-file-input"
                                  accept="image/*"
                                  @change="setImageBannerPlataforma"
                                >
                                <label
                                  class="custom-file-label"
                                  for="banner_plataforma"
                                >Selecione uma imagem...</label>
                                <label class="message-field-label mt-2">Dimensões sugeridas: 2034 x 180 pixels</label>
                                <div class="invalid-feedback">
                                  Arquivo inválido
                                </div>
                                <modal
                                  name="modalImageCropBannerPlataforma"
                                  height="auto"
                                  :shift-y="0.1"
                                  :focus-trap="true"
                                  :adaptive="true"
                                >
                                  <div class="row">
                                    <div class="col-12 pt-4 text-center">
                                      <h4>Recorte a imagem</h4>
                                    </div>
                                    <div
                                      v-if="$store.state.fastPlataforma.banner_plataforma"
                                      class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                                    >
                                      <vue-cropper
                                        ref="cropper"
                                        :src="$store.state.fastPlataforma.banner_plataforma"
                                        :auto-crop-area="1"
                                        :aspect-ratio="96 / 11"
                                      />
                                      <div class="text-center mt-2">
                                        <a
                                          class="btn btn-primary"
                                          href="#"
                                          role="button"
                                          @click.prevent="cropImage('BannerPlataforma')"
                                        >Recortar</a>
                                      </div>
                                    </div>
                                  </div>
                                </modal>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab>
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Cores"
                    >
                      <div class="row justify-content-between">
                        <div class="col-12 mb-4">
                          <h4>Cores do layout</h4>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                          <span
                            v-tooltip.bottom-start="{ content: exibeTooltip('aluno_background_color') }"
                            class="mr-1"
                          >
                            <b-icon-question-circle />
                          </span>
                          <label>Cor de fundo</label>
                          <input
                            v-model="$store.state.fastPersonalizacao.aluno_background_color"
                            class="form-control form-control-lg"
                            type="color"
                            @click="$store.state.fastPersonalizacao.alteracao_pendente = true"
                          >
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                          <span
                            v-tooltip.bottom-start="{ content: exibeTooltip('aluno_font_color') }"
                            class="mr-1"
                          >
                            <b-icon-question-circle />
                          </span>
                          <label>Cor dos textos</label>
                          <input
                            v-model="$store.state.fastPersonalizacao.aluno_font_color"
                            class="form-control form-control-lg"
                            type="color"
                            @click="$store.state.fastPersonalizacao.alteracao_pendente = true"
                          >
                        </div>
                        <div
                          v-if="$store.state.fastPersonalizacao.alteracao_pendente"
                          class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                        >
                          <button
                            class="btn btn-primary blink_me ml-2 mr-2"
                            @click="alteraFastPersonalizacao()"
                          >
                            Salvar alterações
                          </button>
                          <button
                            class="btn btn-secondary ml-2 mr-2"
                            @click="getFastPersonalizacao($route.params.id_plataforma, true)"
                          >
                            Cancelar
                          </button>
                        </div>
                      </div>
                    </tab>
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Menu principal"
                    >
                      <div class="row justify-content-between">
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                          <h4>Itens do menu principal</h4>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-4 text-right">
                          <button
                            class="btn btn-sm btn-danger"
                            @click="showModal('modalRestaurarOriginal')"
                          >
                            <small>Restaurar original</small>
                          </button>
                        </div>
                        <div class="col-12">
                          <div class="row shadow p-3 mb-4 bg-white rounded">
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                              <label>Cor de fundo dos itens do menu</label>
                              <input
                                v-model="$store.state.fastPersonalizacao.menu_background_color"
                                class="form-control form-control-lg"
                                type="color"
                                @click="$store.state.fastPersonalizacao.alteracao_pendente = true"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                              <label>Cor da fonte dos itens do menu</label>
                              <input
                                v-model="$store.state.fastPersonalizacao.menu_font_color"
                                class="form-control form-control-lg"
                                type="color"
                                @click="$store.state.fastPersonalizacao.alteracao_pendente = true"
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <!-- Tutoriais -->
                          <div class="row shadow p-3 mb-4 bg-white rounded">
                            <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                              <div class="row">
                                <div class="col-12 text-center">
                                  <label>
                                    Aluno visualiza
                                    <b>"Tutoriais"</b>?
                                  </label>
                                  <div class="fd-app-meus-cursos mt-0 ml-1">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div
                                        class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                                      >
                                        <label class="switch">
                                          <input
                                            v-if="$store.state.fastPersonalizacao.aluno_visualiza_tutoriais"
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_tutoriais"
                                            checked="checked"
                                            type="checkbox"
                                            id="tutoriais"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <input
                                            v-else
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_tutoriais"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 text-center">
                                  <div class="col-sm-12 col-md-12 col-lg-12 mt-4 p-0">
                                    <!-- upload aqui -->
                                    <input
                                      id="menu_icone_tutoriais"
                                      type="file"
                                      class="custom-file-input"
                                      accept="image/*"
                                      @change="ImageMenuIcone"
                                    >
                                    <label class="message-field-label mt-2">Dimensões sugeridas: 100 x 100 pixels</label>
                                    <label
                                      class="custom-file-label"
                                      for="menu_icone_tutoriais"
                                    >Trocar ícone...</label>
                                    <div class="invalid-feedback">
                                      Arquivo inválido
                                    </div>
                                    <modal
                                      name="modalImageCropMenuIconeTutoriais"
                                      height="auto"
                                      :shift-y="0.1"
                                      :focus-trap="true"
                                      :adaptive="true"
                                    >
                                      <div class="row">
                                        <div class="col-12 pt-4 text-center">
                                          <h4>Recorte a imagem</h4>
                                        </div>
                                        <div
                                          v-if="$store.state.fastPersonalizacao.menu_icone_tutoriais"
                                          class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                                        >
                                          <vue-cropper
                                            ref="cropper"
                                            :src="$store.state.fastPersonalizacao.menu_icone_tutoriais"
                                            :auto-crop-area="1"
                                            :aspect-ratio="1 / 1"
                                          />
                                          <div class="text-center mt-2">
                                            <a
                                              class="btn btn-primary"
                                              href="#"
                                              role="button"
                                              @click.prevent="cropImage('MenuIconeTutoriais')"
                                            >Recortar</a>
                                          </div>
                                        </div>
                                      </div>
                                    </modal>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4 mb-3 fd-mode-opcoes fd-mode-ead mt-0"
                            >
                              <label>Visão do aluno:</label>
                              <div class="fd-mode-opcoes-item">
                                <div class="row align-items-center">
                                  <span
                                    class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                                    :style="{ backgroundColor: $store.state.fastPersonalizacao.menu_background_color ? $store.state.fastPersonalizacao.menu_background_color : '#fff' }"
                                  >
                                    <img
                                      v-if="!$store.state.fastPersonalizacao.menu_icone_tutoriais"
                                      :src="require('@/assets/images/app/question.png')"
                                      class="img-fluid"
                                    >
                                    <img
                                      v-else
                                      :src="$store.state.fastPersonalizacao.menu_icone_tutoriais"
                                      class="img-fluid"
                                    >
                                    <h3
                                      class="mt-0 mt-sm-1"
                                      :style="{ color: $store.state.fastPersonalizacao.menu_font_color ? $store.state.fastPersonalizacao.menu_font_color : '#2a596a' }"
                                    >Tutoriais</h3>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- /Tutoriais -->

                          <!-- Meus cursos -->
                          <div class="row shadow p-3 mb-4 bg-white rounded">
                            <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                              <div class="row">
                                <div class="col-12 text-center">
                                  <label>
                                    Aluno visualiza
                                    <b>"Meus cursos"</b>?
                                  </label>
                                  <div class="fd-app-meus-cursos mt-0 ml-1">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div
                                        class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                                      >
                                        <label class="switch">
                                          <input
                                            v-if="$store.state.fastPersonalizacao.aluno_visualiza_meus_cursos"
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_meus_cursos"
                                            checked="checked"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <input
                                            v-else
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_meus_cursos"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 text-center">
                                  <input
                                    v-model="$store.state.fastPersonalizacao.meus_cursos_nome"
                                    type="text"
                                    class="form-control"
                                    @keydown="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                  >
                                </div>
                                <div class="col-12 text-center">
                                  <div class="col-sm-12 col-md-12 col-lg-12 mt-4 p-0">
                                    <!-- upload aqui -->
                                    <input
                                      id="menu_icone_meus_cursos"
                                      type="file"
                                      class="custom-file-input"
                                      accept="image/*"
                                      @change="ImageMenuIcone"
                                    >
                                    <label class="message-field-label mt-2">Dimensões sugeridas: 100 x 100 pixels</label>
                                    <label
                                      class="custom-file-label"
                                      for="menu_icone_meus_cursos"
                                    >Trocar ícone...</label>
                                    <div class="invalid-feedback">
                                      Arquivo inválido
                                    </div>
                                    <modal
                                      name="modalImageCropMenuIconeMeusCursos"
                                      height="auto"
                                      :shift-y="0.1"
                                      :focus-trap="true"
                                      :adaptive="true"
                                    >
                                      <div class="row">
                                        <div class="col-12 pt-4 text-center">
                                          <h4>Recorte a imagem</h4>
                                        </div>
                                        <div
                                          v-if="$store.state.fastPersonalizacao.menu_icone_meus_cursos"
                                          class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                                        >
                                          <vue-cropper
                                            ref="cropper"
                                            :src="$store.state.fastPersonalizacao.menu_icone_meus_cursos"
                                            :auto-crop-area="1"
                                            :aspect-ratio="1 / 1"
                                          />
                                          <div class="text-center mt-2">
                                            <a
                                              class="btn btn-primary"
                                              href="#"
                                              role="button"
                                              @click.prevent="cropImage('MenuIconeMeusCursos')"
                                            >Recortar</a>
                                          </div>
                                        </div>
                                      </div>
                                    </modal>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4 mb-3 fd-mode-opcoes fd-mode-ead mt-0"
                            >
                              <label>Visão do aluno:</label>
                              <div class="fd-mode-opcoes-item">
                                <div class="row align-items-center">
                                  <span
                                    class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                                    :style="{ backgroundColor: $store.state.fastPersonalizacao.menu_background_color ? $store.state.fastPersonalizacao.menu_background_color : '#fff' }"
                                  >
                                    <img
                                      v-if="!$store.state.fastPersonalizacao.menu_icone_meus_cursos"
                                      :src="require('@/assets/images/app/cursos-icon.png')"
                                      class="img-fluid"
                                    >
                                    <img
                                      v-else
                                      :src="$store.state.fastPersonalizacao.menu_icone_meus_cursos"
                                      class="img-fluid"
                                    >
                                    <h3
                                      class="mt-0 mt-sm-1"
                                      :style="{ color: $store.state.fastPersonalizacao.menu_font_color ? $store.state.fastPersonalizacao.menu_font_color : '#2a596a' }"
                                    >{{ $store.state.fastPersonalizacao.meus_cursos_nome }}</h3>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- /Meus cursos -->

                          <!-- Turmas -->
                          <div class="row shadow p-3 mb-4 bg-white rounded">
                            <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                              <div class="row">
                                <div class="col-12 text-center">
                                  <label>
                                    Aluno visualiza
                                    <b>"Turmas"</b>?
                                  </label>
                                  <div class="fd-app-meus-cursos mt-0 ml-1">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div
                                        class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                                      >
                                        <label class="switch">
                                          <input
                                            v-if="$store.state.fastPersonalizacao.aluno_visualiza_turmas"
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_turmas"
                                            checked="checked"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <input
                                            v-else
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_turmas"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 text-center">
                                  <div class="col-sm-12 col-md-12 col-lg-12 mt-4 p-0">
                                    <!-- upload aqui -->
                                    <input
                                      id="menu_icone_turmas"
                                      type="file"
                                      class="custom-file-input"
                                      accept="image/*"
                                      @change="ImageMenuIcone"
                                    >
                                    <label class="message-field-label mt-2">Dimensões sugeridas: 100 x 100 pixels</label>
                                    <label
                                      class="custom-file-label"
                                      for="menu_icone_turmas"
                                    >Trocar ícone...</label>
                                    <div class="invalid-feedback">
                                      Arquivo inválido
                                    </div>
                                    <modal
                                      name="modalImageCropMenuIconeTurmas"
                                      height="auto"
                                      :shift-y="0.1"
                                      :focus-trap="true"
                                      :adaptive="true"
                                    >
                                      <div class="row">
                                        <div class="col-12 pt-4 text-center">
                                          <h4>Recorte a imagem</h4>
                                        </div>
                                        <div
                                          v-if="$store.state.fastPersonalizacao.menu_icone_turmas"
                                          class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                                        >
                                          <vue-cropper
                                            ref="cropper"
                                            :src="$store.state.fastPersonalizacao.menu_icone_turmas"
                                            :auto-crop-area="1"
                                            :aspect-ratio="1 / 1"
                                          />
                                          <div class="text-center mt-2">
                                            <a
                                              class="btn btn-primary"
                                              href="#"
                                              role="button"
                                              @click.prevent="cropImage('MenuIconeTurmas')"
                                            >Recortar</a>
                                          </div>
                                        </div>
                                      </div>
                                    </modal>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4 mb-3 fd-mode-opcoes fd-mode-ead mt-0"
                            >
                              <label>Visão do aluno:</label>
                              <div class="fd-mode-opcoes-item">
                                <div class="row align-items-center">
                                  <span
                                    class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                                    :style="{ backgroundColor: $store.state.fastPersonalizacao.menu_background_color ? $store.state.fastPersonalizacao.menu_background_color : '#fff' }"
                                  >
                                    <img
                                      v-if="!$store.state.fastPersonalizacao.menu_icone_turmas"
                                      :src="require('@/assets/images/app/certificados-icon.png')"
                                      class="img-fluid"
                                    >
                                    <img
                                      v-else
                                      :src="$store.state.fastPersonalizacao.menu_icone_turmas"
                                      class="img-fluid"
                                    >
                                    <h3
                                      class="mt-0 mt-sm-1"
                                      :style="{ color: $store.state.fastPersonalizacao.menu_font_color ? $store.state.fastPersonalizacao.menu_font_color : '#2a596a' }"
                                    >Turmas</h3>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- /Turmas -->


                          <!-- Minhas lives -->
                          <div class="row shadow p-3 mb-4 bg-white rounded">
                            <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                              <div class="row">
                                <div class="col-12 text-center">
                                  <label>
                                    Aluno visualiza
                                    <b>"Minhas lives"</b>?
                                  </label>
                                  <div class="fd-app-meus-cursos mt-0 ml-1">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div
                                        class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                                      >
                                        <label class="switch">
                                          <input
                                            v-if="$store.state.fastPersonalizacao.aluno_visualiza_minhas_lives"
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_minhas_lives"
                                            checked="checked"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <input
                                            v-else
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_minhas_lives"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 text-center">
                                  <input
                                    v-model="$store.state.fastPersonalizacao.minhas_lives_nome"
                                    type="text"
                                    class="form-control"
                                    @keydown="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                  >
                                </div>
                                <div class="col-12 text-center">
                                  <div class="col-sm-12 col-md-12 col-lg-12 mt-4 p-0">
                                    <!-- upload aqui -->
                                    <input
                                      id="menu_icone_minhas_lives"
                                      type="file"
                                      class="custom-file-input"
                                      accept="image/*"
                                      @change="ImageMenuIcone"
                                    >
                                  <label class="message-field-label mt-2">Dimensões sugeridas: 100 x 100 pixels</label>
                                    <label
                                      class="custom-file-label"
                                      for="menu_icone_minhas_lives"
                                    >Trocar ícone...</label>
                                    <div class="invalid-feedback">
                                      Arquivo inválido
                                    </div>
                                    <modal
                                      name="modalImageCropMenuIconeMinhasLives"
                                      height="auto"
                                      :shift-y="0.1"
                                      :focus-trap="true"
                                      :adaptive="true"
                                    >
                                      <div class="row">
                                        <div class="col-12 pt-4 text-center">
                                          <h4>Recorte a imagem</h4>
                                        </div>
                                        <div
                                          v-if="$store.state.fastPersonalizacao.menu_icone_minhas_lives"
                                          class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                                        >
                                          <vue-cropper
                                            ref="cropper"
                                            :src="$store.state.fastPersonalizacao.menu_icone_minhas_lives"
                                            :auto-crop-area="1"
                                            :aspect-ratio="1 / 1"
                                          />
                                          <div class="text-center mt-2">
                                            <a
                                              class="btn btn-primary"
                                              href="#"
                                              role="button"
                                              @click.prevent="cropImage('MenuIconeMinhasLives')"
                                            >Recortar</a>
                                          </div>
                                        </div>
                                      </div>
                                    </modal>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4 mb-3 fd-mode-opcoes fd-mode-ead mt-0"
                            >
                              <label>Visão do aluno:</label>
                              <div class="fd-mode-opcoes-item">
                                <div class="row align-items-center">
                                  <span
                                    class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                                    :style="{ backgroundColor: $store.state.fastPersonalizacao.menu_background_color ? $store.state.fastPersonalizacao.menu_background_color : '#fff' }"
                                  >
                                    <img
                                      v-if="!$store.state.fastPersonalizacao.menu_icone_minhas_lives"
                                      :src="require('@/assets/images/app/usuarios-icon.png')"
                                      class="img-fluid"
                                    >
                                    <img
                                      v-else
                                      :src="$store.state.fastPersonalizacao.menu_icone_minhas_lives"
                                      class="img-fluid"
                                    >
                                    <h3
                                      class="mt-0 mt-sm-1"
                                      :style="{ color: $store.state.fastPersonalizacao.menu_font_color ? $store.state.fastPersonalizacao.menu_font_color : '#2a596a' }"
                                    >{{ $store.state.fastPersonalizacao.minhas_lives_nome }}</h3>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- /Minhas lives -->
                          <!-- Atendimento -->
                          <div class="row shadow p-3 mb-4 bg-white rounded">
                            <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                              <div class="row">
                                <div class="col-12 text-center">
                                  <label>
                                    Aluno visualiza
                                    <b>"Atendimento"</b>?
                                  </label>
                                  <div class="fd-app-meus-cursos mt-0 ml-1">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div
                                        class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                                      >
                                        <label class="switch">
                                          <input
                                            v-if="$store.state.fastPersonalizacao.aluno_visualiza_atendimento"
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_atendimento"
                                            checked="checked"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <input
                                            v-else
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_atendimento"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 text-center">
                                  <div class="col-sm-12 col-md-12 col-lg-12 mt-4 p-0">
                                    <!-- upload aqui -->
                                    <input
                                      id="menu_icone_atendimento"
                                      type="file"
                                      class="custom-file-input"
                                      accept="image/*"
                                      @change="ImageMenuIcone"
                                    >
                                  <label class="message-field-label mt-2">Dimensões sugeridas: 100 x 100 pixels</label>
                                    <label
                                      class="custom-file-label"
                                      for="menu_icone_atendimento"
                                    >Trocar ícone...</label>
                                    <div class="invalid-feedback">
                                      Arquivo inválido
                                    </div>
                                    <modal
                                      name="modalImageCropMenuIconeAtendimento"
                                      height="auto"
                                      :shift-y="0.1"
                                      :focus-trap="true"
                                      :adaptive="true"
                                    >
                                      <div class="row">
                                        <div class="col-12 pt-4 text-center">
                                          <h4>Recorte a imagem</h4>
                                        </div>
                                        <div
                                          v-if="$store.state.fastPersonalizacao.menu_icone_atendimento"
                                          class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                                        >
                                          <vue-cropper
                                            ref="cropper"
                                            :src="$store.state.fastPersonalizacao.menu_icone_atendimento"
                                            :auto-crop-area="1"
                                            :aspect-ratio="1 / 1"
                                          />
                                          <div class="text-center mt-2">
                                            <a
                                              class="btn btn-primary"
                                              href="#"
                                              role="button"
                                              @click.prevent="cropImage('MenuIconeAtendimento')"
                                            >Recortar</a>
                                          </div>
                                        </div>
                                      </div>
                                    </modal>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4 mb-3 fd-mode-opcoes fd-mode-ead mt-0"
                            >
                              <label>Visão do aluno:</label>
                              <div class="fd-mode-opcoes-item">
                                <div class="row align-items-center">
                                  <span
                                    class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                                    :style="{ backgroundColor: $store.state.fastPersonalizacao.menu_background_color ? $store.state.fastPersonalizacao.menu_background_color : '#fff' }"
                                  >
                                    <img
                                      v-if="!$store.state.fastPersonalizacao.menu_icone_atendimento"
                                      :src="require('@/assets/images/app/atendimento-icon.png')"
                                      class="img-fluid"
                                    >
                                    <img
                                      v-else
                                      :src="$store.state.fastPersonalizacao.menu_icone_atendimento"
                                      class="img-fluid"
                                    >
                                    <h3
                                      class="mt-0 mt-sm-1"
                                      :style="{ color: $store.state.fastPersonalizacao.menu_font_color ? $store.state.fastPersonalizacao.menu_font_color : '#2a596a' }"
                                    >Atendimento</h3>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- /Atendimento -->
                          <!-- Mural -->
                          <div class="row shadow p-3 mb-4 bg-white rounded">
                            <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                              <div class="row">
                                <div class="col-12 text-center">
                                  <label>
                                    Aluno visualiza
                                    <b>"Mural"</b>?
                                  </label>
                                  <div class="fd-app-meus-cursos mt-0 ml-1">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div
                                        class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                                      >
                                        <label class="switch">
                                          <input
                                            v-if="$store.state.fastPersonalizacao.aluno_visualiza_mural"
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_mural"
                                            checked="checked"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <input
                                            v-else
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_mural"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 text-center">
                                  <div class="col-sm-12 col-md-12 col-lg-12 mt-4 p-0">
                                    <!-- upload aqui -->
                                    <input
                                      id="aluno_visualiza_mural"
                                      type="file"
                                      class="custom-file-input"
                                      accept="image/*"
                                      @change="ImageMenuIcone"
                                    >
                                  <label class="message-field-label mt-2">Dimensões sugeridas: 100 x 100 pixels</label>
                                    <label
                                      class="custom-file-label"
                                      for="aluno_visualiza_mural"
                                    >Trocar ícone...</label>
                                    <div class="invalid-feedback">
                                      Arquivo inválido
                                    </div>
                                    <modal
                                      name="modalImageCropMenuIconeMural"
                                      height="auto"
                                      :shift-y="0.1"
                                      :focus-trap="true"
                                      :adaptive="true"
                                    >
                                      <div class="row">
                                        <div class="col-12 pt-4 text-center">
                                          <h4>Recorte a imagem</h4>
                                        </div>
                                        <div
                                          v-if="$store.state.fastPersonalizacao.menu_icone_mural"
                                          class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                                        >
                                          <vue-cropper
                                            ref="cropper"
                                            :src="$store.state.fastPersonalizacao.menu_icone_mural"
                                            :auto-crop-area="1"
                                            :aspect-ratio="1 / 1"
                                          />
                                          <div class="text-center mt-2">
                                            <a
                                              class="btn btn-primary"
                                              href="#"
                                              role="button"
                                              @click.prevent="cropImage('MenuIconeMural')"
                                            >Recortar</a>
                                          </div>
                                        </div>
                                      </div>
                                    </modal>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4 mb-3 fd-mode-opcoes fd-mode-ead mt-0"
                            >
                              <label>Visão do aluno:</label>
                              <div class="fd-mode-opcoes-item">
                                <div class="row align-items-center">
                                  <span
                                    class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                                    :style="{ backgroundColor: $store.state.fastPersonalizacao.menu_background_color ? $store.state.fastPersonalizacao.menu_background_color : '#fff' }"
                                  >
                                    <img
                                      v-if="!$store.state.fastPersonalizacao.menu_icone_mural"
                                      :src="require('@/assets/images/app/mural-icon.png')"
                                      class="img-fluid"
                                    >
                                    <img
                                      v-else
                                      :src="$store.state.fastPersonalizacao.menu_icone_mural"
                                      class="img-fluid"
                                    >
                                    <h3
                                      class="mt-0 mt-sm-1"
                                      :style="{ color: $store.state.fastPersonalizacao.menu_font_color ? $store.state.fastPersonalizacao.menu_font_color : '#2a596a' }"
                                    >Mural</h3>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- /Mural -->
                          <!-- Meus cadernos -->
                          <div class="row shadow p-3 mb-4 bg-white rounded">
                            <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                              <div class="row">
                                <div class="col-12 text-center">
                                  <label>
                                    Aluno visualiza
                                    <b>"Meus cadernos"</b>?
                                  </label>
                                  <div class="fd-app-meus-cursos mt-0 ml-1">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div
                                        class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                                      >
                                        <label class="switch">
                                          <input
                                            v-if="$store.state.fastPersonalizacao.aluno_visualiza_meus_cadernos"
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_meus_cadernos"
                                            checked="checked"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <input
                                            v-else
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_meus_cadernos"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 text-center">
                                  <input
                                    v-model="$store.state.fastPersonalizacao.meus_cadernos_nome"
                                    type="text"
                                    class="form-control"
                                    @keydown="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                  >
                                </div>
                                <div class="col-12 text-center">
                                  <div class="col-sm-12 col-md-12 col-lg-12 mt-4 p-0">
                                    <!-- upload aqui -->
                                    <input
                                      id="aluno_visualiza_meus_cadernos"
                                      type="file"
                                      class="custom-file-input"
                                      accept="image/*"
                                      @change="ImageMenuIcone"
                                    >
                                  <label class="message-field-label mt-2">Dimensões sugeridas: 100 x 100 pixels</label>
                                    <label
                                      class="custom-file-label"
                                      for="aluno_visualiza_meus_cadernos"
                                    >Trocar ícone...</label>
                                    <div class="invalid-feedback">
                                      Arquivo inválido
                                    </div>
                                    <modal
                                      name="modalImageCropMenuIconeMeusCadernos"
                                      height="auto"
                                      :shift-y="0.1"
                                      :focus-trap="true"
                                      :adaptive="true"
                                    >
                                      <div class="row">
                                        <div class="col-12 pt-4 text-center">
                                          <h4>Recorte a imagem</h4>
                                        </div>
                                        <div
                                          v-if="$store.state.fastPersonalizacao.menu_icone_meus_cadernos"
                                          class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                                        >
                                          <vue-cropper
                                            ref="cropper"
                                            :src="$store.state.fastPersonalizacao.menu_icone_meus_cadernos"
                                            :auto-crop-area="1"
                                            :aspect-ratio="1 / 1"
                                          />
                                          <div class="text-center mt-2">
                                            <a
                                              class="btn btn-primary"
                                              href="#"
                                              role="button"
                                              @click.prevent="cropImage('MenuIconeMeusCadernos')"
                                            >Recortar</a>
                                          </div>
                                        </div>
                                      </div>
                                    </modal>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4 mb-3 fd-mode-opcoes fd-mode-ead mt-0"
                            >
                              <label>Visão do aluno:</label>
                              <div class="fd-mode-opcoes-item">
                                <div class="row align-items-center">
                                  <span
                                    class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                                    :style="{ backgroundColor: $store.state.fastPersonalizacao.menu_background_color ? $store.state.fastPersonalizacao.menu_background_color : '#fff' }"
                                  >
                                    <img
                                      v-if="!$store.state.fastPersonalizacao.menu_icone_meus_cadernos"
                                      :src="require('@/assets/images/app/certificados-icon.png')"
                                      class="img-fluid"
                                    >
                                    <img
                                      v-else
                                      :src="$store.state.fastPersonalizacao.menu_icone_meus_cadernos"
                                      class="img-fluid"
                                    >
                                    <h3
                                      class="mt-0 mt-sm-1"
                                      :style="{ color: $store.state.fastPersonalizacao.menu_font_color ? $store.state.fastPersonalizacao.menu_font_color : '#2a596a' }"
                                    >{{ $store.state.fastPersonalizacao.meus_cadernos_nome }}</h3>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- /Meus cadernos -->
                          <!-- Minhas capacitações -->
                          <div class="row shadow p-3 mb-4 bg-white rounded">
                            <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                              <div class="row">
                                <div class="col-12 text-center">
                                  <label>
                                    Aluno visualiza
                                    <b>"Minhas capacitações"</b>?
                                  </label>
                                  <div class="fd-app-meus-cursos mt-0 ml-1">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div
                                        class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                                      >
                                        <label class="switch">
                                          <input
                                            v-if="$store.state.fastPersonalizacao.aluno_visualiza_minhas_capacitacoes"
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_minhas_capacitacoes"
                                            checked="checked"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <input
                                            v-else
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_minhas_capacitacoes"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 d-flex align-items-center text-center">
                                  <span
                                    v-tooltip.bottom-start="{ content: exibeTooltip('minhas_capacitacoes_nome') }"
                                    class="mr-1"
                                  >
                                    <b-icon-question-circle />
                                  </span>
                                  <input
                                    v-model="$store.state.fastPersonalizacao.minhas_capacitacoes_nome"
                                    type="text"
                                    class="form-control"
                                    @keydown="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                  >
                                </div>
                                <div class="col-12 d-flex align-items-center text-center mt-2">
                                  <span
                                    v-tooltip.bottom-start="{ content: exibeTooltip('minhas_capacitacoes_setor') }"
                                    class="mr-1"
                                  >
                                    <b-icon-question-circle />
                                  </span>
                                  <input
                                    v-model="$store.state.fastPersonalizacao.minhas_capacitacoes_setor"
                                    type="text"
                                    class="form-control"
                                    @keydown="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                  >
                                </div>
                                <div class="col-12 d-flex align-items-center text-center mt-2">
                                  <span
                                    v-tooltip.bottom-start="{ content: exibeTooltip('minhas_capacitacoes_cargo') }"
                                    class="mr-1"
                                  >
                                    <b-icon-question-circle />
                                  </span>
                                  <input
                                    v-model="$store.state.fastPersonalizacao.minhas_capacitacoes_cargo"
                                    type="text"
                                    class="form-control"
                                    @keydown="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                  >
                                </div>
                                <div class="col-12 text-center">
                                  <div class="col-sm-12 col-md-12 col-lg-12 mt-4 p-0">
                                    <!-- upload aqui -->
                                    <input
                                      id="menu_icone_minhas_capacitacoes"
                                      type="file"
                                      class="custom-file-input"
                                      accept="image/*"
                                      @change="ImageMenuIcone"
                                    >
                                  <label class="message-field-label mt-2">Dimensões sugeridas: 100 x 100 pixels</label>

                                    <label
                                      class="custom-file-label"
                                      for="menu_icone_minhas_capacitacoes"
                                    >Trocar ícone...</label>
                                    <div class="invalid-feedback">
                                      Arquivo inválido
                                    </div>
                                    <modal
                                      name="modalImageCropMenuIconeMinhasCapacitacoes"
                                      height="auto"
                                      :shift-y="0.1"
                                      :focus-trap="true"
                                      :adaptive="true"
                                    >
                                      <div class="row">
                                        <div class="col-12 pt-4 text-center">
                                          <h4>Recorte a imagem</h4>
                                        </div>
                                        <div
                                          v-if="$store.state.fastPersonalizacao.menu_icone_minhas_capacitacoes"
                                          class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                                        >
                                          <vue-cropper
                                            ref="cropper"
                                            :src="$store.state.fastPersonalizacao.menu_icone_minhas_capacitacoes"
                                            :auto-crop-area="1"
                                            :aspect-ratio="1 / 1"
                                          />
                                          <div class="text-center mt-2">
                                            <a
                                              class="btn btn-primary"
                                              href="#"
                                              role="button"
                                              @click.prevent="cropImage('MenuIconeMinhasCapacitacoes')"
                                            >Recortar</a>
                                          </div>
                                        </div>
                                      </div>
                                    </modal>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4 mb-3 fd-mode-opcoes fd-mode-ead mt-0"
                            >
                              <label>Visão do aluno:</label>
                              <div class="fd-mode-opcoes-item">
                                <div class="row align-items-center">
                                  <span
                                    class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                                    :style="{ backgroundColor: $store.state.fastPersonalizacao.menu_background_color ? $store.state.fastPersonalizacao.menu_background_color : '#fff' }"
                                  >
                                    <img
                                      v-if="!$store.state.fastPersonalizacao.menu_icone_minhas_capacitacoes"
                                      :src="require('@/assets/images/app/capacitacoes-icon.png')"
                                      class="img-fluid"
                                    >
                                    <img
                                      v-else
                                      :src="$store.state.fastPersonalizacao.menu_icone_minhas_capacitacoes"
                                      class="img-fluid"
                                    >
                                    <h3
                                      class="mt-0 mt-sm-1"
                                      :style="{ color: $store.state.fastPersonalizacao.menu_font_color ? $store.state.fastPersonalizacao.menu_font_color : '#2a596a' }"
                                    >{{ $store.state.fastPersonalizacao.minhas_capacitacoes_nome }}</h3>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- /Minhas capacitações -->
                          <!-- Loja da plataforma -->
                          <div class="row shadow p-3 mb-4 bg-white rounded">
                            <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                              <div class="row">
                                <div class="col-12 text-center">
                                  <label>
                                    Aluno visualiza
                                    <b>"Loja da plataforma"</b>?
                                  </label>
                                  <div class="fd-app-meus-cursos mt-0 ml-1">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div
                                        class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                                      >
                                        <label class="switch">
                                          <input
                                            v-if="$store.state.fastPersonalizacao.aluno_visualiza_loja_plataforma"
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_loja_plataforma"
                                            checked="checked"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <input
                                            v-else
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_loja_plataforma"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 text-center">
                                  <div class="col-sm-12 col-md-12 col-lg-12 mt-4 p-0">
                                    <!-- upload aqui -->
                                    <input
                                      id="menu_icone_loja_plataforma"
                                      type="file"
                                      class="custom-file-input"
                                      accept="image/*"
                                      @change="ImageMenuIcone"
                                    >
                                  <label class="message-field-label mt-2">Dimensões sugeridas: 100 x 100 pixels</label>
                                    <label
                                      class="custom-file-label"
                                      for="menu_icone_loja_plataforma"
                                    >Trocar ícone...</label>
                                    <div class="invalid-feedback">
                                      Arquivo inválido
                                    </div>
                                    <modal
                                      name="modalImageCropMenuIconeLojaPlataforma"
                                      height="auto"
                                      :shift-y="0.1"
                                      :focus-trap="true"
                                      :adaptive="true"
                                    >
                                      <div class="row">
                                        <div class="col-12 pt-4 text-center">
                                          <h4>Recorte a imagem</h4>
                                        </div>
                                        <div
                                          v-if="$store.state.fastPersonalizacao.menu_icone_loja_plataforma"
                                          class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                                        >
                                          <vue-cropper
                                            ref="cropper"
                                            :src="$store.state.fastPersonalizacao.menu_icone_loja_plataforma"
                                            :auto-crop-area="1"
                                            :aspect-ratio="1 / 1"
                                          />
                                          <div class="text-center mt-2">
                                            <a
                                              class="btn btn-primary"
                                              href="#"
                                              role="button"
                                              @click.prevent="cropImage('MenuIconeLojaPlataforma')"
                                            >Recortar</a>
                                          </div>
                                        </div>
                                      </div>
                                    </modal>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4 mb-3 fd-mode-opcoes fd-mode-ead mt-0"
                            >
                              <label>Visão do aluno:</label>
                              <div class="fd-mode-opcoes-item">
                                <div class="row align-items-center">
                                  <span
                                    class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                                    :style="{ backgroundColor: $store.state.fastPersonalizacao.menu_background_color ? $store.state.fastPersonalizacao.menu_background_color : '#fff' }"
                                  >
                                    <img
                                      v-if="!$store.state.fastPersonalizacao.menu_icone_loja_plataforma"
                                      :src="require('@/assets/images/app/ondemand-icon.png')"
                                      class="img-fluid"
                                    >
                                    <img
                                      v-else
                                      :src="$store.state.fastPersonalizacao.menu_icone_loja_plataforma"
                                      class="img-fluid"
                                    >
                                    <h3
                                      class="mt-0 mt-sm-1"
                                      :style="{ color: $store.state.fastPersonalizacao.menu_font_color ? $store.state.fastPersonalizacao.menu_font_color : '#2a596a' }"
                                    >Loja da plataforma</h3>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- /Loja da plataforma -->
                          <!-- Meus Requerimentos -->
                          <div class="row shadow p-3 mb-4 bg-white rounded">
                            <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                              <div class="row">
                                <div class="col-12 text-center">
                                  <label>
                                    Aluno visualiza
                                    <b>"Meus Requerimentos"</b>?
                                  </label>
                                  <div class="fd-app-meus-cursos mt-0 ml-1">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div
                                        class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                                      >
                                        <label class="switch">
                                          <input
                                            v-if="$store.state.fastPersonalizacao.aluno_visualiza_requerimentos"
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_requerimentos"
                                            checked="checked"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <input
                                            v-else
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_requerimentos"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 text-center">
                                  <div class="col-sm-12 col-md-12 col-lg-12 mt-4 p-0">
                                    <!-- upload aqui -->
                                    <input
                                      id="menu_icone_meus_requerimentos"
                                      type="file"
                                      class="custom-file-input"
                                      accept="image/*"
                                      @change="ImageMenuIcone"
                                    >
                                  <label class="message-field-label mt-2">Dimensões sugeridas: 100 x 100 pixels</label>
                                    <label
                                      class="custom-file-label"
                                      for="menu_icone_meus_requerimentos"
                                    >Trocar ícone...</label>
                                    <div class="invalid-feedback">
                                      Arquivo inválido
                                    </div>
                                    <modal
                                      name="modalImageCropMenuIconeMeusRequerimentos"
                                      height="auto"
                                      :shift-y="0.1"
                                      :focus-trap="true"
                                      :adaptive="true"
                                    >
                                      <div class="row">
                                        <div class="col-12 pt-4 text-center">
                                          <h4>Recorte a imagem</h4>
                                        </div>
                                        <div
                                          v-if="$store.state.fastPersonalizacao.menu_icone_requerimentos"
                                          class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                                        >
                                          <vue-cropper
                                            ref="cropper"
                                            :src="$store.state.fastPersonalizacao.menu_icone_requerimentos"
                                            :auto-crop-area="1"
                                            :aspect-ratio="1 / 1"
                                          />
                                          <div class="text-center mt-2">
                                            <a
                                              class="btn btn-primary"
                                              href="#"
                                              role="button"
                                              @click.prevent="cropImage('MenuIconeMeusRequerimentos')"
                                            >Recortar</a>
                                          </div>
                                        </div>
                                      </div>
                                    </modal>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4 mb-3 fd-mode-opcoes fd-mode-ead mt-0"
                            >
                              <label>Visão do aluno:</label>
                              <div class="fd-mode-opcoes-item">
                                <div class="row align-items-center">
                                  <span
                                    class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                                    :style="{ backgroundColor: $store.state.fastPersonalizacao.menu_background_color ? $store.state.fastPersonalizacao.menu_background_color : '#fff' }"
                                  >
                                    <img
                                      v-if="!$store.state.fastPersonalizacao.menu_icone_requerimentos"
                                      :src="require('@/assets/images/app/requerimentos.png')"
                                      class="img-fluid"
                                    >
                                    <img
                                      v-else
                                      :src="$store.state.fastPersonalizacao.menu_icone_requerimentos"
                                      class="img-fluid"
                                    >
                                    <h3
                                      class="mt-0 mt-sm-1"
                                      :style="{ color: $store.state.fastPersonalizacao.menu_font_color ? $store.state.fastPersonalizacao.menu_font_color : '#2a596a' }"
                                    >Meus Requerimentos</h3>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- /Meus Requerimentos -->
                          <!-- Minhas Ocorrencias -->
                          <div class="row shadow p-3 mb-4 bg-white rounded">
                            <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                              <div class="row">
                                <div class="col-12 text-center">
                                  <label>
                                    Aluno visualiza
                                    <b>"Minhas Ocorrências"</b>?
                                  </label>
                                  <div class="fd-app-meus-cursos mt-0 ml-1">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div
                                        class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                                      >
                                        <label class="switch">
                                          <input
                                            v-if="$store.state.fastPersonalizacao.aluno_visualiza_ocorrencias"
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_ocorrencias"
                                            checked="checked"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <input
                                            v-else
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_ocorrencias"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 text-center">
                                  <div class="col-sm-12 col-md-12 col-lg-12 mt-4 p-0">
                                    <!-- upload aqui -->
                                    <input
                                      id="menu_icone_minhas_ocorrencias"
                                      type="file"
                                      class="custom-file-input"
                                      accept="image/*"
                                      @change="ImageMenuIcone"
                                    >
                                  <label class="message-field-label mt-2">Dimensões sugeridas: 100 x 100 pixels</label>
                                    <label
                                      class="custom-file-label"
                                      for="menu_icone_minhas_ocorrencias"
                                    >Trocar ícone...</label>
                                    <div class="invalid-feedback">
                                      Arquivo inválido
                                    </div>
                                    <modal
                                      name="modalImageCropMenuIconeMinhasOcorrencias"
                                      height="auto"
                                      :shift-y="0.1"
                                      :focus-trap="true"
                                      :adaptive="true"
                                    >
                                      <div class="row">
                                        <div class="col-12 pt-4 text-center">
                                          <h4>Recorte a imagem</h4>
                                        </div>
                                        <div
                                          v-if="$store.state.fastPersonalizacao.menu_icone_ocorrencias"
                                          class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                                        >
                                          <vue-cropper
                                            ref="cropper"
                                            :src="$store.state.fastPersonalizacao.menu_icone_ocorrencias"
                                            :auto-crop-area="1"
                                            :aspect-ratio="1 / 1"
                                          />
                                          <div class="text-center mt-2">
                                            <a
                                              class="btn btn-primary"
                                              href="#"
                                              role="button"
                                              @click.prevent="cropImage('MenuIconeMinhasOcorrencias')"
                                            >Recortar</a>
                                          </div>
                                        </div>
                                      </div>
                                    </modal>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4 mb-3 fd-mode-opcoes fd-mode-ead mt-0"
                            >
                              <label>Visão do aluno:</label>
                              <div class="fd-mode-opcoes-item">
                                <div class="row align-items-center">
                                  <span
                                    class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                                    :style="{ backgroundColor: $store.state.fastPersonalizacao.menu_background_color ? $store.state.fastPersonalizacao.menu_background_color : '#fff' }"
                                  >
                                    <img
                                      v-if="!$store.state.fastPersonalizacao.menu_icone_ocorrencias"
                                      :src="require('@/assets/images/app/ocorrencias.png')"
                                      class="img-fluid"
                                    >
                                    <img
                                      v-else
                                      :src="$store.state.fastPersonalizacao.menu_icone_ocorrencias"
                                      class="img-fluid"
                                    >
                                    <h3
                                      class="mt-0 mt-sm-1"
                                      :style="{ color: $store.state.fastPersonalizacao.menu_font_color ? $store.state.fastPersonalizacao.menu_font_color : '#2a596a' }"
                                    >Minhas Ocorrências</h3>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- /Minhas Ocorrencias -->
                          <!-- Perguntas -->
                          <div class="row shadow p-3 mb-4 bg-white rounded">
                            <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                              <div class="row">
                                <div class="col-12 text-center">
                                  <label>
                                    Aluno visualiza
                                    <b>"Perguntas"</b>?
                                  </label>
                                  <div class="fd-app-meus-cursos mt-0 ml-1">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div
                                        class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                                      >
                                        <label class="switch">
                                          <input
                                            v-if="$store.state.fastPersonalizacao.aluno_visualiza_perguntas"
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_perguntas"
                                            checked="checked"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <input
                                            v-else
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_perguntas"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 text-center">
                                  <div class="col-sm-12 col-md-12 col-lg-12 mt-4 p-0">
                                    <!-- upload aqui -->
                                    <input
                                      id="menu_icone_perguntas"
                                      type="file"
                                      class="custom-file-input"
                                      accept="image/*"
                                      @change="ImageMenuIcone"
                                    >
                                  <label class="message-field-label mt-2">Dimensões sugeridas: 100 x 100 pixels</label>
                                    <label
                                      class="custom-file-label"
                                      for="menu_icone_perguntas"
                                    >Trocar ícone...</label>
                                    <div class="invalid-feedback">
                                      Arquivo inválido
                                    </div>
                                    <modal
                                      name="modalImageCropMenuIconePerguntas"
                                      height="auto"
                                      :shift-y="0.1"
                                      :focus-trap="true"
                                      :adaptive="true"
                                    >
                                      <div class="row">
                                        <div class="col-12 pt-4 text-center">
                                          <h4>Recorte a imagem</h4>
                                        </div>
                                        <div
                                          v-if="$store.state.fastPersonalizacao.menu_icone_perguntas"
                                          class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                                        >
                                          <vue-cropper
                                            ref="cropper"
                                            :src="$store.state.fastPersonalizacao.menu_icone_perguntas"
                                            :auto-crop-area="1"
                                            :aspect-ratio="1 / 1"
                                          />
                                          <div class="text-center mt-2">
                                            <a
                                              class="btn btn-primary"
                                              href="#"
                                              role="button"
                                              @click.prevent="cropImage('MenuIconePerguntas')"
                                            >Recortar</a>
                                          </div>
                                        </div>
                                      </div>
                                    </modal>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4 mb-3 fd-mode-opcoes fd-mode-ead mt-0"
                            >
                              <label>Visão do aluno:</label>
                              <div class="fd-mode-opcoes-item">
                                <div class="row align-items-center">
                                  <span
                                    class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                                    :style="{ backgroundColor: $store.state.fastPersonalizacao.menu_background_color ? $store.state.fastPersonalizacao.menu_background_color : '#fff' }"
                                  >
                                    <img
                                      v-if="!$store.state.fastPersonalizacao.menu_icone_perguntas"
                                      :src="require('@/assets/images/app/question.png')"
                                      class="img-fluid"
                                    >
                                    <img
                                      v-else
                                      :src="$store.state.fastPersonalizacao.menu_icone_perguntas"
                                      class="img-fluid"
                                    >
                                    <h3
                                      class="mt-0 mt-sm-1"
                                      :style="{ color: $store.state.fastPersonalizacao.menu_font_color ? $store.state.fastPersonalizacao.menu_font_color : '#2a596a' }"
                                    >Perguntas</h3>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- /Perguntas -->
                          <!-- Aula particular -->
                          <div class="row shadow p-3 mb-4 bg-white rounded">
                            <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                              <div class="row">
                                <div class="col-12 text-center">
                                  <label>
                                    Aluno visualiza
                                    <b>"Aula Particular"</b>?
                                  </label>
                                  <div class="fd-app-meus-cursos mt-0 ml-1">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div
                                        class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                                      >
                                        <label class="switch">
                                          <input
                                            v-if="$store.state.fastPersonalizacao.aluno_visualiza_aula_particular"
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_aula_particular"
                                            checked="checked"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <input
                                            v-else
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_aula_particular"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 text-center">
                                  <div class="col-sm-12 col-md-12 col-lg-12 mt-4 p-0">
                                    <!-- upload aqui -->
                                    <input
                                      id="menu_icone_aula_particular"
                                      type="file"
                                      class="custom-file-input"
                                      accept="image/*"
                                      @change="ImageMenuIcone"
                                    >
                                  <label class="message-field-label mt-2">Dimensões sugeridas: 100 x 100 pixels</label>
                                    <label
                                      class="custom-file-label"
                                      for="menu_icone_aula_particular"
                                    >Trocar ícone...</label>
                                    <div class="invalid-feedback">
                                      Arquivo inválido
                                    </div>
                                    <modal
                                      name="modalImageCropMenuIconeAulaParticular"
                                      height="auto"
                                      :shift-y="0.1"
                                      :focus-trap="true"
                                      :adaptive="true"
                                    >
                                      <div class="row">
                                        <div class="col-12 pt-4 text-center">
                                          <h4>Recorte a imagem</h4>
                                        </div>
                                        <div
                                          v-if="$store.state.fastPersonalizacao.menu_icone_aula_particular"
                                          class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                                        >
                                          <vue-cropper
                                            ref="cropper"
                                            :src="$store.state.fastPersonalizacao.menu_icone_aula_particular"
                                            :auto-crop-area="1"
                                            :aspect-ratio="1 / 1"
                                          />
                                          <div class="text-center mt-2">
                                            <a
                                              class="btn btn-primary"
                                              href="#"
                                              role="button"
                                              @click.prevent="cropImage('MenuIconeAulaParticular')"
                                            >Recortar</a>
                                          </div>
                                        </div>
                                      </div>
                                    </modal>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4 mb-3 fd-mode-opcoes fd-mode-ead mt-0"
                            >
                              <label>Visão do aluno:</label>
                              <div class="fd-mode-opcoes-item">
                                <div class="row align-items-center">
                                  <span
                                    class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                                    :style="{ backgroundColor: $store.state.fastPersonalizacao.menu_background_color ? $store.state.fastPersonalizacao.menu_background_color : '#fff' }"
                                  >
                                    <img
                                      v-if="!$store.state.fastPersonalizacao.menu_icone_aula_particular"
                                      :src="require('@/assets/images/app/teaching.png')"
                                      class="img-fluid"
                                    >
                                    <img
                                      v-else
                                      :src="$store.state.fastPersonalizacao.menu_icone_aula_particular"
                                      class="img-fluid"
                                    >
                                    <h3
                                      class="mt-0 mt-sm-1"
                                      :style="{ color: $store.state.fastPersonalizacao.menu_font_color ? $store.state.fastPersonalizacao.menu_font_color : '#2a596a' }"
                                    >Aula Particular</h3>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- /Aula Particular -->
                          <!-- Revisão de redação -->
                          <div class="row shadow p-3 mb-4 bg-white rounded">
                            <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                              <div class="row">
                                <div class="col-12 text-center">
                                  <label>
                                    Aluno visualiza
                                    <b>"Revisão de redação"</b>?
                                  </label>
                                  <div class="fd-app-meus-cursos mt-0 ml-1">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div
                                        class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                                      >
                                        <label class="switch">
                                          <input
                                            v-if="$store.state.fastPersonalizacao.aluno_visualiza_revisao_redacao"
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_revisao_redacao"
                                            checked="checked"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <input
                                            v-else
                                            v-model="$store.state.fastPersonalizacao.aluno_visualiza_revisao_redacao"
                                            type="checkbox"
                                            @change="$store.state.fastPersonalizacao.alteracao_pendente = true"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 text-center">
                                  <div class="col-sm-12 col-md-12 col-lg-12 mt-4 p-0">
                                    <!-- upload aqui -->
                                    <input
                                      id="menu_icone_revisao_redacao"
                                      type="file"
                                      class="custom-file-input"
                                      accept="image/*"
                                      @change="ImageMenuIcone"
                                    >
                                  <label class="message-field-label mt-2">Dimensões sugeridas: 100 x 100 pixels</label>
                                    <label
                                      class="custom-file-label"
                                      for="menu_icone_revisao_redacao"
                                    >Trocar ícone...</label>
                                    <div class="invalid-feedback">
                                      Arquivo inválido
                                    </div>
                                    <modal
                                      name="modalImageCropMenuIconeRevisaoRedacao"
                                      height="auto"
                                      :shift-y="0.1"
                                      :focus-trap="true"
                                      :adaptive="true"
                                    >
                                      <div class="row">
                                        <div class="col-12 pt-4 text-center">
                                          <h4>Recorte a imagem</h4>
                                        </div>
                                        <div
                                          v-if="$store.state.fastPersonalizacao.menu_icone_revisao_redacao"
                                          class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                                        >
                                          <vue-cropper
                                            ref="cropper"
                                            :src="$store.state.fastPersonalizacao.menu_icone_revisao_redacao"
                                            :auto-crop-area="1"
                                            :aspect-ratio="1 / 1"
                                          />
                                          <div class="text-center mt-2">
                                            <a
                                              class="btn btn-primary"
                                              href="#"
                                              role="button"
                                              @click.prevent="cropImage('MenuIconeRevisaoRedacao')"
                                            >Recortar</a>
                                          </div>
                                        </div>
                                      </div>
                                    </modal>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4 mb-3 fd-mode-opcoes fd-mode-ead mt-0"
                            >
                              <label>Visão do aluno:</label>
                              <div class="fd-mode-opcoes-item">
                                <div class="row align-items-center">
                                  <span
                                    class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                                    :style="{ backgroundColor: $store.state.fastPersonalizacao.menu_background_color ? $store.state.fastPersonalizacao.menu_background_color : '#fff' }"
                                  >
                                    <img
                                      v-if="!$store.state.fastPersonalizacao.menu_icone_revisao_redacao"
                                      :src="require('@/assets/images/app/responsive.png')"
                                      class="img-fluid"
                                    >
                                    <img
                                      v-else
                                      :src="$store.state.fastPersonalizacao.menu_icone_revisao_redacao"
                                      class="img-fluid"
                                    >
                                    <h3
                                      class="mt-0 mt-sm-1"
                                      :style="{ color: $store.state.fastPersonalizacao.menu_font_color ? $store.state.fastPersonalizacao.menu_font_color : '#2a596a' }"
                                    >Revisao de redação</h3>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- /Revisão de redação -->


                        </div>

                        <div
                          v-if="$store.state.fastPersonalizacao.alteracao_pendente"
                          class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                        >
                          <button
                            class="btn btn-primary blink_me ml-2 mr-2"
                            @click="alteraFastPersonalizacao()"
                          >
                            Salvar alterações
                          </button>
                          <button
                            class="btn btn-secondary ml-2 mr-2"
                            @click="getFastPersonalizacao($route.params.id_plataforma, true)"
                          >
                            Cancelar
                          </button>
                        </div>
                      </div>
                    </tab>
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Parâmetros"
                    >
                      <div class="row">
                        <div
                          v-if="fastPlataformaParametros.id_plataforma"
                          class="col-sm-12 col-md-12 col-lg-12"
                        >
                          <h6>Gateway de pagamento interno</h6>
                          <div class="shadow-sm p-3 mb-4 bg-white rounded">
                            <div v-if="fastPlataformaGateway.loading">
                              <b-icon icon="gear-fill" animation="spin"/> Carregando 
                            </div>
                            <div v-else>
                              <div class="form-group">
                                <select class="form-control" v-model="fastPlataformaGateway.id_gateway" @change="fastPlataformaGateway.alteracoes_pendentes = true">
                                  <option value="0">Nenhum gateway selecionado</option>
                                  <option v-for="e in fastTesourariaGateway.lista" :value="e.id_gateway" :key="e.id_gateway"> {{e.nome_gateway}} </option>
                                </select>
                              </div>
                              <div v-if="fastPlataformaGateway.id_gateway" class="form-group">                              
                                <label>Client ID <span class="text-danger">*</span> </label>
                                <input
                                  v-model="fastPlataformaGateway.token"
                                  @keyup="fastPlataformaGateway.alteracoes_pendentes = true"
                                  type="text"
                                  :class="fastPlataformaGateway.token ? 'form-control' : 'form-control border border-danger text-danger'"                                  
                                >
                              </div>
                              <div v-if="fastPlataformaGateway.id_gateway" class="form-group">
                                <label>Cliente Secret <span class="text-danger">*</span> </label>
                                <input
                                  v-model="fastPlataformaGateway.api_secret"
                                  @keyup="fastPlataformaGateway.alteracoes_pendentes = true"
                                  type="text"
                                  :class="fastPlataformaGateway.api_secret ? 'form-control' : 'form-control border border-danger text-danger'"                         
                                >
                              </div>
                              <div v-if="fastPlataformaGateway.alteracoes_pendentes" class="form-group text-center">
                                <button class="btn btn-sm btn-primary blink_me" @click="atualizaPlataformaGateway()">Salvar alterações</button>
                              </div>
                            </div>
                          </div>
                          
                          <h6>Geral</h6>
                          <div class="shadow-sm p-3 mb-4 bg-white rounded">
                            <div class="form-group">
                              <label>Domínio</label>
                              <input
                                v-model="fastPlataformaParametros.url_plataforma"
                                type="text"
                                class="form-control"
                                readonly
                              >
                            </div>
                            <div class="form-group">
                              <label>Representante</label>
                              <input
                                v-model="fastPlataformaParametros.representante"
                                type="text"
                                class="form-control"
                                readonly
                              >
                            </div>
                            <div class="form-group">
                              <label>Licenças</label>
                              <input
                                v-model="fastPlataformaParametros.licenca"
                                type="text"
                                class="form-control"
                                readonly
                              >
                            </div>
                          </div>
                          <h6>SSO (Single Sign-On)</h6>
                          <div class="shadow-sm p-3 mb-4 bg-white rounded">
                            <div class="form-group">
                              <label>Token SSO</label>
                              <input
                                v-model="fastPlataformaParametros.sso_token"
                                type="text"
                                class="form-control"
                                readonly
                              >
                            </div>
                            <div class="form-group">
                              <label>Url SSO</label>
                              <input
                                v-model="fastPlataformaParametros.sso_url"
                                type="text"
                                class="form-control"
                                readonly
                              >
                            </div>
                            <a
                              href="https://fastead.com/developer"
                              target="_blank"
                              title="Ver documentação"
                            >Ver documentação</a>
                          </div>
                          <h6>Gateway de pagamento externo</h6>
                          <div class="shadow-sm p-3 mb-4 bg-white rounded">
                            <div class="form-group">
                              <label>Token Checkout</label>
                              <input
                                v-model="fastPlataformaParametros.checkout_token"
                                type="text"
                                class="form-control"
                                readonly
                              >
                            </div>
                            <div class="form-group">
                              <label>Url Checkout</label>
                              <input
                                v-model="fastPlataformaParametros.checkout_url"
                                type="text"
                                class="form-control"
                                readonly
                              >
                            </div>
                            <a
                              href="https://fastead.com/developer"
                              target="_blank"
                              title="Ver documentação"
                            >Ver documentação</a>
                          </div>

                        </div>
                      </div>
                    </tab>
                  </tabs>
                </div>
              </div>

              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modals -->

    <modal
      name="modalRestaurarOriginal"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
      :width="modalWidthSm"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-12 pl-4 pr-4">
          <h4>Restaurar original?</h4>
        </div>
        <div
          class="col-12 mt-4 pl-4 pr-4"
        >
          Ao confirmar você irá restaurar a personalização original de menus do sistema FastEAD.
        </div>
        <div class="col-12 mt-4 pl-4 pr-4">
          <button
            class="btn btn-danger mr-2"
            @click="restaurarOriginal()"
          >
            <small>Confirmar</small>
          </button>
          <button
            class="btn btn-secondary"
            @click="hideModal('modalRestaurarOriginal')"
          >
            <small>Cancelar</small>
          </button>
        </div>
      </div>
    </modal>
    <!-- Notificações -->
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "HomeInternoAdminPersonalizarPlataforma",
  components: {
    VueCropper,
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      fastPlataforma: {
        id_plataforma: 0,
        nome_plataforma: "",
        alteracao_pendente: false,
      },
      // Parâmetros
      fastPlataformaParametros: {},
      // Gateway
      fastTesourariaGateway: {
        lista: [],
        loading: true
      },
      fastPlataformaGateway: {
        id_plataforma: 0,
        id_gateway: 0,
        nome_gateway: "",
        token: "",
        api_secret: "",
        alteracoes_pendentes: false,
        loading: true        
      }
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'administrador')
        .then(() => {
          this.getFastPlataformaParametros();
          this.getTesourariaGateway(this.$route.params.id_plataforma)
          this.getPlataformaGateway(this.$route.params.id_plataforma)
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async alteraFastPersonalizacao() {
      if (!this.$store.state.fastPersonalizacao.id_plataforma)
        this.$store.state.fastPersonalizacao.id_plataforma = this.$route.params.id_plataforma;
      this.$store.state.fastCarregando = true;

      console.log(this.fastPersonalizacao);
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_personalizacao/atualiza",
          this.fastAjaxOptions(
            "POST",
            JSON.stringify(this.$store.state.fastPersonalizacao)
          )
        );
        let json = await resp.json();
        console.log("j", json);
        this.$store.state.fastCarregando = false;
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Alterações salvas com sucesso",
        });
        this.getFastPersonalizacao(
          this.$store.state.fastPermissoes.id_plataforma,
          true
        );
        if (this.$store.state.fastPersonalizacao.aluno_font_color) {
          Array.from(document.querySelectorAll(".aluno_font_color")).forEach(
            (e) =>
              (e.style.color = this.$store.state.fastPersonalizacao.aluno_font_color)
          );
        }
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Erro ao salvar alterações",
        });
      }
    },
    async alteraFastPlataforma(obj) {
      this.$store.state.fastCarregando = true;
      //console.log("alteraFastPlataforma", obj);
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma/atualiza",
          this.fastAjaxOptions("POST", JSON.stringify(obj))
        );
        let json = await resp.json();
        console.log(json);
        this.$store.state.fastCarregando = false;
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Alterações salvas com sucesso",
        });
        this.getFastPersonalizacao(this.$route.params.id_plataforma);
        this.buscaAPIPlataforma(this.$route.params.id_plataforma).then(
          (obj) => {
            if (obj.length > 0) {
              this.$store.state.fastPlataforma = obj[0];
              this.setFastSessao(
                settings.fastSessaoPlataforma,
                JSON.stringify(this.$store.state.fastPlataforma)
              );
            }
          }
        );
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Erro ao salvar alterações",
        });
      }
    },
    async alteraFastPlataformaNome() {
      if (!this.$store.state.fastPlataforma) {
        this.$toast.open({
          message: "O nome da plataforma não pode ficar me branco.",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
      } else if (this.$store.state.fastPlataforma.length > 100) {
        this.$toast.open({
          message:
            "O nome da plataforma deve possuir no máximo 100 caracteres.",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
      } else if (this.$store.state.fastPlataforma.length > 100) {
        this.$toast.open({
          message: "O nome admin é bloqueado para uso. Configure outro nome.",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
      } else {
        this.$store.state.fastCarregando = true;
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_plataforma/atualiza_nome",
            this.fastAjaxOptions(
              "POST",
              JSON.stringify(this.$store.state.fastPlataforma)
            )
          );
          let json = await resp.json();
          this.$store.state.fastCarregando = false;
          this.$store.state.fastPlataforma.alteracao_pendente = false;
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Alterações salvas com sucesso",
          });

          this.buscaAPIPlataforma(this.$route.params.id_plataforma).then(
            (obj) => {
              if (obj.length > 0) {
                this.$store.state.fastPlataforma = obj[0];
                this.setFastSessao(
                  settings.fastSessaoPlataforma,
                  JSON.stringify(this.$store.state.fastPlataforma)
                );
              }
            }
          );
        } catch (e) {
          console.log("Erro", e);
          this.$store.state.fastCarregando = false;
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Erro ao salvar alterações",
          });
        }
      }
    },
    async restaurarOriginal() {
      this.$store.state.fastCarregando = true;

      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_personalizacao/restaurar",
          this.fastAjaxOptions(
            "POST",
            JSON.stringify(this.$store.state.fastPersonalizacao)
          )
        );
        let json = await resp.json();
        this.$store.state.fastCarregando = false;
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Alterações salvas com sucesso",
        });
        this.hideModal("modalRestaurarOriginal");
        this.getFastPersonalizacao(this.$route.params.id_plataforma, true);
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Erro ao salvar alterações",
        });
      }
    },

    //imageCropper
    cropImage(tipo) {
      if (tipo == "BannerMeusCursos") {
        this.$store.state.fastPersonalizacao.banner_meus_cursos = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        this.alteraFastPersonalizacao();
        this.hideModal("modalImageCropBannerMeusCursos");
      } else if (tipo == "ImagemInformativa") {
        this.$store.state.fastPersonalizacao.imagem_informativa = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        this.alteraFastPersonalizacao();
        this.hideModal("modalImageCropImagemInformativa");
      } else if (tipo == "LogoPlataforma") {
        this.$store.state.fastPlataforma.logo_plataforma = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        let fast_plataforma = {
          id_plataforma: this.$store.state.fastPlataforma.id_plataforma,
          logo_plataforma: this.$store.state.fastPlataforma.logo_plataforma,
          banner_plataforma: this.$store.state.fastPlataforma.banner_plataforma,
        };
        this.alteraFastPlataforma(fast_plataforma);
        this.hideModal("modalImageCropLogoPlataforma");
      } else if (tipo == "BannerPlataforma") {
        this.banner_plataforma = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        let fast_plataforma = {
          id_plataforma: this.$store.state.fastPlataforma.id_plataforma,
          logo_plataforma: this.$store.state.fastPlataforma.logo_plataforma,
          banner_plataforma: this.$store.state.fastPlataforma.banner_plataforma,
        };
        this.alteraFastPlataforma(fast_plataforma);

        this.hideModal("modalImageCropBannerPlataforma");
      } else if (tipo == "MenuIconeMeusCursos") {
        this.$store.state.fastPersonalizacao.menu_icone_meus_cursos = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        this.alteraFastPersonalizacao();
        this.hideModal("modalImageCropMenuIconeMeusCursos");
      } else if (tipo == "MenuIconeMinhasLives") {
        this.$store.state.fastPersonalizacao.menu_icone_minhas_lives = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        this.alteraFastPersonalizacao();
        this.hideModal("modalImageCropMenuIconeMinhasLives");
      } else if (tipo == "MenuIconeAtendimento") {
        this.$store.state.fastPersonalizacao.menu_icone_atendimento = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        this.alteraFastPersonalizacao();
        this.hideModal("modalImageCropMenuIconeAtendimento");
      } else if (tipo == "MenuIconeMural") {
        this.$store.state.fastPersonalizacao.menu_icone_mural = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        this.alteraFastPersonalizacao();
        this.hideModal("modalImageCropMenuIconeMural");
      } else if (tipo == "MenuIconeMeusCadernos") {
        this.$store.state.fastPersonalizacao.menu_icone_meus_cadernos = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        this.alteraFastPersonalizacao();
        this.hideModal("modalImageCropMenuIconeMeusCadernos");
      } else if (tipo == "MenuIconeMinhasCapacitacoes") {
        this.$store.state.fastPersonalizacao.menu_icone_minhas_capacitacoes = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        this.alteraFastPersonalizacao();
        this.hideModal("modalImageCropMenuIconeMinhasCapacitacoes");
      } else if (tipo == "MenuIconeLojaPlataforma") {
        this.$store.state.fastPersonalizacao.menu_icone_loja_plataforma = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        this.alteraFastPersonalizacao();
        this.hideModal("modalImageCropMenuIconeLojaPlataforma");
      } else if (tipo == "MenuIconeTutoriais") {
        this.$store.state.fastPersonalizacao.menu_icone_tutoriais = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        this.alteraFastPersonalizacao();
        this.hideModal("modalImageCropMenuIconeTutoriais");
      } else if (tipo == "MenuIconeTurmas") {
        this.$store.state.fastPersonalizacao.menu_icone_turmas = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        this.alteraFastPersonalizacao();
        this.hideModal("modalImageCropMenuIconeTurmas");
      } else if (tipo == "MenuIconeMeusRequerimentos") {
        this.$store.state.fastPersonalizacao.menu_icone_requerimentos = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        this.alteraFastPersonalizacao();
        this.hideModal("modalImageCropMenuIconeMeusRequerimentos");
      } else if (tipo == "MenuIconeMinhasOcorrencias") {
        this.$store.state.fastPersonalizacao.menu_icone_ocorrencias = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        this.alteraFastPersonalizacao();
        this.hideModal("modalImageCropMenuIconeMinhasOcorrencias");
      } else if (tipo == "MenuIconePerguntas") {
        this.$store.state.fastPersonalizacao.menu_icone_perguntas = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        this.alteraFastPersonalizacao();
        this.hideModal("modalImageCropMenuIconePerguntas");
      } else if (tipo == "MenuIconeAulaParticular") {
        this.$store.state.fastPersonalizacao.menu_icone_aula_particular = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        this.alteraFastPersonalizacao();
        this.hideModal("modalImageCropMenuIconeAulaParticular");
      } else if (tipo == "MenuIconeRevisaoRedacao") {
        this.$store.state.fastPersonalizacao.menu_icone_revisao_redacao = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        this.alteraFastPersonalizacao();
        this.hideModal("modalImageCropMenuIconeRevisaoRedacao");
      }
    },
    
    setImageBannerMeusCursos(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        this.exibeToasty("Não utilize imagens acima de 1MB", "error");
        return;
      }

      this.showModal("modalImageCropBannerMeusCursos");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.$store.state.fastPersonalizacao.banner_meus_cursos =
            event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    setImageImagemInformativa(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        this.exibeToasty("Não utilize imagens acima de 1MB", "error");
        return;
      }

      this.showModal("modalImageCropImagemInformativa");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.$store.state.fastPersonalizacao.imagem_informativa =
            event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    setImageLogoPlataforma(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        this.exibeToasty("Não utilize imagens acima de 1MB", "error");
        return;
      }

      this.showModal("modalImageCropLogoPlataforma");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.$store.state.fastPlataforma.logo_plataforma =
            event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    setImageBannerPlataforma(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        this.exibeToasty("Não utilize imagens acima de 1MB", "error");
        return;
      }

      this.showModal("modalImageCropBannerPlataforma");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.$store.state.fastPlataforma.banner_plataforma =
            event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    ImageMenuIcone(e){
      console.log(e)
      if(e.srcElement.id == "menu_icone_tutoriais"){
        const file = e.target.files[0];
         console.log(e)
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        this.exibeToasty("Não utilize imagens acima de 1MB", "error");
        return;
      }

      this.showModal("modalImageCropMenuIconeTutoriais");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.$store.state.fastPersonalizacao.menu_icone_tutoriais =
            event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      }else if (e.srcElement.id == "menu_icone_revisao_redacao"){
         const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (file.size / 1024 > 1024) {
        this.exibeToasty("Não utilize imagens acima de 1MB", "error");
        return;
      }
      this.showModal("modalImageCropMenuIconeRevisaoRedacao");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.$store.state.fastPersonalizacao.menu_icone_revisao_redacao =
            event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      } else if(e.srcElement.id == "menu_icone_aula_particular"){
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        this.exibeToasty("Não utilize imagens acima de 1MB", "error");
        return;
      }

      this.showModal("modalImageCropMenuIconeAulaParticular");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.$store.state.fastPersonalizacao.menu_icone_aula_particular =
            event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      } else if(e.srcElement.id == "menu_icone_perguntas"){
        const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        this.exibeToasty("Não utilize imagens acima de 1MB", "error");
        return;
      }

      this.showModal("modalImageCropMenuIconePerguntas");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.$store.state.fastPersonalizacao.menu_icone_perguntas =
            event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      } else if(e.srcElement.id == "menu_icone_minhas_ocorrencias"){
        const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        this.exibeToasty("Não utilize imagens acima de 1MB", "error");
        return;
      }

      this.showModal("modalImageCropMenuIconeMinhasOcorrencias");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.$store.state.fastPersonalizacao.menu_icone_ocorrencias =
            event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      } else if(e.srcElement.id == "menu_icone_meus_requerimentos"){
        const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        this.exibeToasty("Não utilize imagens acima de 1MB", "error");
        return;
      }

      this.showModal("modalImageCropMenuIconeMeusRequerimentos");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.$store.state.fastPersonalizacao.menu_icone_requerimentos =
            event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      } else if(e.srcElement.id == "menu_icone_loja_plataforma"){
        const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        this.exibeToasty("Não utilize imagens acima de 1MB", "error");
        return;
      }

      this.showModal("modalImageCropMenuIconeLojaPlataforma");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.$store.state.fastPersonalizacao.menu_icone_loja_plataforma =
            event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      } else if(e.srcElement.id == "menu_icone_minhas_capacitacoes"){
        const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        this.exibeToasty("Não utilize imagens acima de 1MB", "error");
        return;
      }

      this.showModal("modalImageCropMenuIconeMinhasCapacitacoes");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.$store.state.fastPersonalizacao.menu_icone_minhas_capacitacoes =
            event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      } else if(e.srcElement.id == "aluno_visualiza_meus_cadernos"){
        const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        this.exibeToasty("Não utilize imagens acima de 1MB", "error");
        return;
      }

      this.showModal("modalImageCropMenuIconeMeusCadernos");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.$store.state.fastPersonalizacao.menu_icone_meus_cadernos =
            event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      } else if(e.srcElement.id == "aluno_visualiza_mural"){
        const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        this.exibeToasty("Não utilize imagens acima de 1MB", "error");
        return;
      }

      this.showModal("modalImageCropMenuIconeMural");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.$store.state.fastPersonalizacao.menu_icone_mural =
            event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      } else if(e.srcElement.id == "menu_icone_atendimento"){
        const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        this.exibeToasty("Não utilize imagens acima de 1MB", "error");
        return;
      }

      this.showModal("modalImageCropMenuIconeAtendimento");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.$store.state.fastPersonalizacao.menu_icone_atendimento =
            event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      } else if(e.srcElement.id == "menu_icone_minhas_lives"){
        const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        this.exibeToasty("Não utilize imagens acima de 1MB", "error");
        return;
      }

      this.showModal("modalImageCropMenuIconeMinhasLives");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.$store.state.fastPersonalizacao.menu_icone_minhas_lives =
            event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      } else if(e.srcElement.id == "menu_icone_meus_cursos"){
        const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        this.exibeToasty("Não utilize imagens acima de 1MB", "error");
        return;
      }

      this.showModal("modalImageCropMenuIconeMeusCursos");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.$store.state.fastPersonalizacao.menu_icone_meus_cursos =
            event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      } else if(e.srcElement.id == "menu_icone_turmas"){
        const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        this.exibeToasty("Não utilize imagens acima de 1MB", "error");
        return;
      }

      this.showModal("modalImageCropMenuIconeTurmas");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.$store.state.fastPersonalizacao.menu_icone_turmas =
            event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      }
    },

    // Parâmetros da plataforma
    async getFastPlataformaParametros() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_parametro/lista?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastPlataformaParametros = obj[0];
          console.log("PlataformasParams", this.fastPlataformaParametros)
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async alteraUrlZoom() {
      this.fastPlataformaParametros.alteracao_pendente = false;
      if (this.fastPlataformaParametros.url_zoom) {
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_plataforma_parametro/atualiza",
            this.fastAjaxOptions(
              "POST",
              JSON.stringify(this.fastPlataformaParametros)
            )
          );
          let json = await resp.json();
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Alterações salvas com sucesso",
          });
          this.getFastPlataformaParametros();
        } catch (e) {
          console.log("Erro", e);
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Erro ao salvar alterações",
          });
        }
      }
    },
    // Help
    exibeTooltip(tipo) {
      switch (tipo) {
        case "aluno_background_color":
          return (
            '<p class="text-info"><b>Cor de fundo do site</b></p>' +
            '<ol class="text-info pl-4 pr-4 text-left">' +
            "<li>Você pode alterar a cor de fundo da sua plataforma. Essa configuração será aplicada somente dentro da sua plataforma.</li>" +
            "</ol>"
          );
          break;
        case "aluno_font_color":
          return (
            '<p class="text-info"><b>Cor dos textos</b></p>' +
            '<ol class="text-info pl-4 pr-4 text-left">' +
            "<li>Você pode alterar a cor padrão dos textos da sua plataforma. Essa configuração será aplicada somente dentro da sua plataforma.</li>" +
            "</ol>"
          );
          break;
        case "banner_meus_cursos":
          return (
            '<p class="text-info"><b>Banner meus cursos</b></p>' +
            '<ol class="text-info pl-4 pr-4 text-left">' +
            '<li>Imagem que é exibida para o aluno na tela "Meus Cursos" canto superior.</li>' +
            "</ol>"
          );
          break;
        case "detalhes_boas_vindas":
          return (
            '<p class="text-info"><b>Detalhes boas vindas</b></p>' +
            '<ol class="text-info pl-4 pr-4 text-left">' +
            "<li>Texto que aparecerá logo abaixo do título de boas vindas. Utilize a varíavel [nome_aluno] para exibir o nome do aluno que estará visualizando.</li>" +
            "</ol>"
          );
          break;
        case "titulo_boas_vindas":
          return (
            '<p class="text-info"><b>Título de boas vindas</b></p>' +
            '<ol class="text-info pl-4 pr-4 text-left">' +
            "<li>Título de destaque exibido na tela inicial do modo aluno. Utilize a varíavel [nome_aluno] para exibir o nome do aluno que estará visualizando.</li>" +
            "</ol>"
          );
          break;
        case "imagem_informativa":
          return (
            '<p class="text-info"><b>Imagem informativa</b></p>' +
            '<ol class="text-info pl-4 pr-4 text-left">' +
            "<li>Imagem de merchandising que será exibida na Live FastEAD.</li>" +
            "</ol>"
          );
          break;
        case "menu_background_color":
          return (
            '<p class="text-info"><b>Cor de fundo do menu</b></p>' +
            '<ol class="text-info pl-4 pr-4 text-left">' +
            "<li>Cor de fundo dos itens do menu principal. Essa configuração será aplicada somente dentro da sua plataforma.</li>" +
            "</ol>"
          );
          break;
        case "menu_font_color":
          return (
            '<p class="text-info"><b>Cor dos título dos menu principal</b></p>' +
            '<ol class="text-info pl-4 pr-4 text-left">' +
            "<li>Cor dos textos dos itens do menu principal. Essa configuração será aplicada somente dentro da sua plataforma.</li>" +
            "</ol>"
          );
          break;
        case "minhas_capacitacoes_cargo":
          return (
            '<p class="text-info"><b>Alterar nome cargo</b></p>' +
            '<ol class="text-info pl-4 pr-4 text-left">' +
            '<li>A matriz de capacitação é organizada em Setores e Cargos por padrão. Você pode alterar o nome "cargo" que é exibido dentro da matriz de capacitação.</li>' +
            "</ol>"
          );
          break;
        case "minhas_capacitacoes_nome":
          return (
            '<p class="text-info"><b>Alterar nome da matriz de capacitação</b></p>' +
            '<ol class="text-info pl-4 pr-4 text-left">' +
            '<li>Você pode alterar o nome "Matriz de capacitação" para qualquer nome que você desejar.</li>' +
            "</ol>"
          );
          break;
        case "minhas_capacitacoes_setor":
          return (
            '<p class="text-info"><b>Alterar nome setor</b></p>' +
            '<ol class="text-info pl-4 pr-4 text-left">' +
            '<li>A matriz de capacitação é organizada em Setores e Cargos por padrão. Você pode alterar o nome "setor" que é exibido dentro da matriz de capacitação.</li>' +
            "</ol>"
          );
          break;
        case "titulo_banner":
          return (
            '<p class="text-info"><b>Título do banner</b></p>' +
            '<ol class="text-info pl-4 pr-4 text-left">' +
            "<li>Título exibido no canto superior na tela principal do modo aluno. Ele ficará em cima da imagem do banner da plataforma.</li>" +
            "</ol>"
          );
          break;
        case "titulo_banner_color":
          return (
            '<p class="text-info"><b>Cor do título do banner</b></p>' +
            '<ol class="text-info pl-4 pr-4 text-left">' +
            "<li>Você pode alterar a cor do título do banner exibido no canto superior na tela principal do modo aluno.</li>" +
            "</ol>"
          );
          break;
          break;
        case "meus_cursos_nome":
          return (
            '<p class="text-info"><b>Meus cursos</b></p>' +
            '<ol class="text-info pl-4 pr-4 text-left">' +
            "<li>Você pode alterar o título desse menu para o que desejar.</li>" +
            "</ol>"
          );
          break;
        case "minhas_lives_nome":
          return (
            '<p class="text-info"><b>Minhas lives</b></p>' +
            '<ol class="text-info pl-4 pr-4 text-left">' +
            "<li>Você pode alterar o título desse menu para o que desejar.</li>" +
            "</ol>"
          );
          break;
        case "meus_cadernos_nome":
          return (
            '<p class="text-info"><b>Meus cadernos</b></p>' +
            '<ol class="text-info pl-4 pr-4 text-left">' +
            "<li>Você pode alterar o título desse menu para o que desejar.</li>" +
            "</ol>"
          );
          break;
        case "loja_plataforma_nome":
          return (
            '<p class="text-info"><b>Loja da plataforma</b></p>' +
            '<ol class="text-info pl-4 pr-4 text-left">' +
            "<li>Você pode alterar o título desse menu para o que desejar.</li>" +
            "</ol>"
          );
          break;
        case "banner_plataforma":
          return (
            '<p class="text-info"><b>Banner da plataforma</b></p>' +
            '<ol class="text-info pl-4 pr-4 text-left">' +
            "<li>Imagem exibida no canto superior na tela principal do modo aluno.</li>" +
            "</ol>"
          );
          break;
        case "logo_plataforma":
          return (
            '<p class="text-info"><b>Logotipo da plataforma</b></p>' +
            '<ol class="text-info pl-4 pr-4 text-left">' +
            "<li>Logotipo padrão utilizado para identificar visualmente as plataformas.</li>" +
            "</ol>"
          );
          break;
        default:
          break;
      }
    },
    // Gateway
    async getTesourariaGateway(id_plataforma){
      this.fastTesourariaGateway.loading = true
      this.promiseGetFastApi("api/fast_tesouraria_gateway/lista", `id_plataforma=${id_plataforma}`)
      .then((res) => {
        console.log("getTesourariaGateway", res)
        if (res.length) {
          this.fastTesourariaGateway.lista = res
        } else {
          this.fastTesourariaGateway.lista = []
        }
        this.fastTesourariaGateway.loading = false
      })
      .catch(e => {
        this.exibeToasty(e, "error")
        this.fastTesourariaGateway.loading = false
      })
    },
    async getPlataformaGateway(id_plataforma){
      this.fastPlataformaGateway.loading = true
      this.promiseGetFastApi("api/fast_plataforma_gateway/lista", `id_plataforma=${id_plataforma}`)
      .then((res) => {
        console.log("getPlataformaGateway", res)
        if (res.length) {
          res[0].loading = false
          res[0].alteracoes_pendentes = false
          this.fastPlataformaGateway = res[0]
          console.log("PlataformaGateway",this.fastPlataformaGateway)
        } else {
          this.fastPlataformaGateway.loading = false
        }
      })
      .catch(e => {
        this.exibeToasty(e, "error")
        this.fastPlataformaGateway.loading = false
      })
    },
    async atualizaPlataformaGateway(){
      let erros = []
      this.fastPlataformaGateway.id_plataforma = this.$route.params.id_plataforma;
      if (!this.fastPlataformaGateway.id_gateway) erros.push("Selecione um gateway de pagamento") 
      if (!this.fastPlataformaGateway.token) erros.push("O Client ID é obrigatório") 
      if (!this.fastPlataformaGateway.api_secret) erros.push("O Client Secret é obrigatório") 

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.fastPlataformaGateway.token = this.fastPlataformaGateway.token.trim()
        this.fastPlataformaGateway.api_secret = this.fastPlataformaGateway.api_secret.trim()
        this.fastPlataformaGateway.alteracoes_pendentes = false
        this.promisePostFastApi(this.fastPlataformaGateway, "api/fast_plataforma_gateway/insere")
        .then(res => {                
          if (res.length){
            this.exibeToasty("Gateway salvo com sucesso", "success")
          }
          this.fastPlataformaGateway.alteracoes_pendentes = false
        })
        .catch((e) => {                
          this.exibeToasty(`${e}`, "error")
          this.fastPlataformaGateway.alteracoes_pendentes = false
        });
      }
    }
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.fd-app-curso-aulas .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item,
.fd-app-meus-cursos .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item {
  font-family: initial;
}

.ql-container {
  height: auto !important;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
/* Tree */
.icon:hover {
  cursor: pointer;
}

.muted {
  color: gray;
  font-size: 80%;
}

/* Tooltip */
.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: #fff;
  color: #000;
  border-radius: 16px;
  padding: 5px 10px 4px;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  width: 300px;
  min-width: 300px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

.message-field-label {
  display: flex;
  align-items: left;
  font-size: 12px;
  color:#495057;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
</style>